// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { AuthProvider } from './components/Auth/AuthContext';
import AppTest from './components/Auth/AppTest.js';
import PrivateRoute from './components/Auth/PrivateRoute.js';
import Home from './components/Home/Home';
import PrivacyPolicy from './components/Policies/PrivacyPolicy';
import Organisations from './components/Organisations/Organisations';
import DeletePolicy from './components/Policies/DeletePolicy';
import BiauMainFrame from './components/ErpSystem/BiauMainFrame.js';
import DownloadsPage from './components/Dowloads/DownloadPage.js';
import BiauBiometrics from './components/Wiki/BiauBiometrics.js';
import ResetBiau from './components/Biau/ResetBiau.js';
import CreateEmail from './components/Wiki/CreateEmail.js';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Biau" element={<PrivateRoute><BiauMainFrame /></PrivateRoute>} />
          <Route path="/organisations/*" element={<PrivateRoute><Organisations /></PrivateRoute>} />

          <Route path="/apptest" element={<AppTest />} />
          <Route path="/register/*" element={<DownloadsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/delete-policy" element={<DeletePolicy />} />
          <Route path="/wiki/biau-activation" element={<BiauBiometrics />} />
          <Route path="/wiki/create-email" element={<CreateEmail />} />
          <Route path="/biau/biau-reset" element={<ResetBiau />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
