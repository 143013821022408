import React, { useState, useEffect } from 'react';
import './Organisations.css'; // Include the styling file
import Papa from 'papaparse';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress'; // Add this for the loading spinner

function Organisations() {
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false); // State for opening/closing the form dialog
  const [isCsvFormType, setIsCsvFormType] = useState(false); // State for distinguishing between CSV and manual input
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [verticalUsers, setVerticalUsers] = useState([]); 
  const [parsedData, setParsedData] = useState([]);
  const [email, setNewEmail] = useState('');
  const [biauLocation, setBiauLocation] = useState('');
  const [orgName, setOrgName] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [backgroundUrl, setBackgroundUrl] = useState('');
  const [verticalDate, setVerticalDate] = useState('');
  const [verticalLocation, setVerticalLocation] = useState('');
  const [shorthand, setShortHand] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [communicationEmail, setCommunicationEmail] = useState('');
  const [verticals, setVerticals] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [biauType, setBiauType] = useState('');
  const [paymentInfo, setPaymentInfo] = useState({ amount: '', periodicity: '' }); // Store   
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [color, setColor] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // New state for current page
  const [toggleForm, setToggleForm] = useState(false); // State for number of users per page, default 10
  const [toggleFormType, setToggleFormType] = useState(true); // State for number of users per page, default 10
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [pageSize, setPageSize] = useState(5); // Default rows per page
  const [rowCount, setRowCount] = useState(0); // Total rows (provided by backend)
  const [dataRows, setDataRows] = useState([]); // Rows to display in DataGrid
  const [filterInput, setFilterInput] = useState('');
  const [filterColumn, setFilterColumn] = useState('Email');
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 });
  const [selectedRows, setSelectedRows] = useState([]); // Selected rows for deletion
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editUserData, setEditUserData] = useState({
    email: '',
    title: '',
    description: '',
    verticalDate: '',
    logoUrl: '',
    backgroundUrl: '',
    verticalType: '',
    color:'',
    verticalId:'',
  });
  const handleConfirmEdit = async (e) => {
    e.preventDefault()
    const payload = {
      ShortHand: shorthand.toUpperCase(),
      VerticalName: capitalizeFirstLetter(activeTab),
      VerticalId: editUserData.verticalId, // Assuming `selectedRows` contains the vertical ID
      Title: editUserData.title,
      Description: editUserData.description,
      Color: editUserData.color,
      VerticalDate: editUserData.verticalDate,
      LogoUrl: editUserData.logoUrl,
      BackgroundUrl: editUserData.backgroundUrl,
      Email: editUserData.email,
    };
  
    const url = 'https://efwns762x6i6uok7lg5pefh6zq0mbxyj.lambda-url.eu-west-2.on.aws/';
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        
        body:  JSON.stringify(payload),
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log('Update successful:', result);
  
        // Update the user data locally in the table after a successful API call
        setDataRows((prevRows) =>
          prevRows.map((row) =>
            row.id === selectedRows[0] ? { ...row, ...editUserData } : row
          )
        );
  
        // Close the edit dialog
        setIsEditDialogOpen(false);
      } else {
        console.error('Failed to update vertical');
      }
    } catch (error) {
      console.error('Error updating vertical:', error);
    }
  };
  
  const handleEditClick = () => {
    
    // Get the selected user data to prefill in the dialog
    const selectedUser = dataRows.find((row) => row.id === selectedRows[0]);
    if (selectedUser) {
      setEditUserData({
        email: selectedUser.email,
        title: selectedUser.title,
        description: selectedUser.description,
        logoUrl: selectedUser.logoUrl,
        backgroundUrl: selectedUser.backgroundUrl,
        color: selectedUser.color,
        verticalType: selectedUser.type,
        verticalDate: selectedUser.date,
        verticalId: selectedUser.verticalId,
      });
    }
  
    // Open the edit dialog
    setIsEditDialogOpen(true);
  };
  // Function to handle opening the delete confirmation dialog
  const handleDeleteClick = () => {
    if (selectedRows.length > 0) {
      setIsDeleteDialogOpen(true);
    }
  };
  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
  };
    // Function to handle the deletion of selected rows
  const handleConfirmDelete = async () => {
    try {
      const deleteRequests = selectedRows.map((rowId) => {
        const row = dataRows.find((dataRow) => dataRow.id === rowId);
        return {
          VerticalName: capitalizeFirstLetter(activeTab),
          ShortHand: shorthand.toUpperCase(),
          VerticalId: row.verticalId,
          BiauEmail: row.email,
        };
      });

      const response = await fetch('https://sjjzri6oacybhtjximacqruldu0ixrpl.lambda-url.eu-west-2.on.aws/', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify(deleteRequests),
      });

      if (response.ok) {
        setDataRows((prevRows) => prevRows.filter((row) => !selectedRows.includes(row.id)));
        setSelectedRows([]);
      } else {
        console.error('Failed to delete users');
      }
    } catch (error) {
      console.error('Error deleting users:', error);
    }

    setIsDeleteDialogOpen(false);
  };
  const handleOpenFormDialog = () => {
    setIsFormDialogOpen(true);
  };
  
  const handleCloseFormDialog = () => {
    setIsFormDialogOpen(false);
  };
  
  // Function to handle closing the delete confirmation dialog
  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };
  const formatExclusiveStartKey = (lastEvaluatedKey, primaryKey) => {
    if (lastEvaluatedKey) {
      return {
        [primaryKey]: {
          S: lastEvaluatedKey
        }
      };
    }
    return null; // or undefined if no key is available
  };
const fetchFilteredData = async (page, pageSize, applyFilter, lastEvaluatedKeyOverride = lastEvaluatedKey) => {
  const keyToUse = lastEvaluatedKeyOverride !== undefined ? lastEvaluatedKeyOverride : lastEvaluatedKey;
    try {
      const url = 'https://snv5dvrxnzcdz7kwcxkkschrzm0whhyx.lambda-url.eu-west-2.on.aws/';
      const primaryKey = `${capitalizeFirstLetter(activeTab)}Id`; // Construct the primary key dynamically
          // Format the lastEvaluatedKey as required
      const formattedExclusiveStartKey = keyToUse ? formatExclusiveStartKey(lastEvaluatedKey, primaryKey) : null;

      const requestBody = {
        ShortHand: shorthand.toUpperCase(),
        VerticalName: capitalizeFirstLetter(activeTab),
        Limit: pageSize,
        ...(formattedExclusiveStartKey && { ExclusiveStartKey: formattedExclusiveStartKey }),
        Filters: applyFilter ? [{
          Column: capitalizeFirstLetter(filterColumn),
          Operator: 'CONTAINS',
          Value: filterInput,
        }] : [],
      };
  
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (response.ok) {
        const data = await response.json();
  
        const transformedRows = data.Items.map((item, index) => ({
          id: index + 1,
          email: item.BiauEmail?.S || 'No Email',
          title: item.Title?.S || 'No Title',
          type: item.Type?.S || 'No Type',
          description: item.Description?.S || 'No Description',
          color: item.Color?.S || 'No Color',
          date: item.Date?.S || 'No Date',
          location: item.Location?.S || 'No Location',
          logoUrl: item.LogoUrl?.S || 'No LogoUrl',
          backgroundUrl: item.BackgroundUrl?.S || 'No BackgroundUrl',
          verticalId: item[`${capitalizeFirstLetter(activeTab)}Id`]?.S || 'No VerticalId',
        }));
        
        setDataRows(transformedRows);
        setLastEvaluatedKey(data.LastEvaluatedKey[`${capitalizeFirstLetter(activeTab)}Id`].S || null); // Extract the new key properly
        setRowCount(data.RowCount || 0); // Update total rows for pagination
      } else {
        console.error('Failed to fetch filtered data');
      }
    } catch (error) {
      console.error('Error fetching filtered data:', error);
    }
  };
  

  useEffect(() => {
    if (activeTab) {
      fetchFilteredData(currentPage, pageSize);
    }
  }, [activeTab, currentPage]);

  const requiredFields = [
    "Email",
    "Title",
    "Type",
    "ShortHand",
    "Description",
    "Color",
    "Date",
    "Location",
    "LogoUrl",
    "BackgroundUrl",
  ];

  useEffect(() => {
    if(activeTab){
      fetchFilteredData(currentPage, pageSize);
    }
  }, [currentPage, pageSize]);
  
  const handleFileChange = (event) => {
    if(!event) return;
    const file = event.target.files[0];
    if (!file) return;
    
    Papa.parse(file, {
      header: true,
      complete: function(results) {
    
        const validationResult = validateCSVData(results.data);
    
        if (!validationResult.isValid) {
          const errorMessages = validationResult.errors.map(
            error => `Row ${error.row} is missing the following fields: ${error.missingFields.join(', ')}`
          );
          alert(`CSV validation failed:\n${errorMessages.join('\n')}`);
          return;
        }
              
      // Filter out completely empty rows before mapping the data
      const filteredData = results.data.filter(entry => {
        // Check if all fields in a row are empty
        const isRowEmpty = requiredFields.every(field => !entry[field] || entry[field].trim() === '');
        return !isRowEmpty; // Only keep rows that are not empty
      });

      // Map parsed CSV data to the VerticalRequest structure
      const mappedData = filteredData.map(entry => ({
        VerticalName: capitalizeFirstLetter(activeTab),
        ShortHand: shorthand.toUpperCase(),
        BiauEmail: entry.Email.toLocaleLowerCase(),
        OrganisationName: orgName,
        VerticalTitle: entry.Title,
        VerticalType: entry.Type,
        VerticalDescription: entry.Description,
        VerticalColor: entry.Color,
        VerticalDate: entry.Date,
        VerticalLocation: entry.Location,
        LogoUrl: entry.LogoUrl,
        BackgroundUrl: entry.BackgroundUrl,
        CommunicationEmail: communicationEmail
      }));


      // Continue with the rest of your logic (e.g., setting state, handling bulk upload, etc.)
      setParsedData(mappedData);
      }
    });
  };

  const getColumns = (headers) => {
    return headers.map((header) => ({
      field: header.charAt(0).toLowerCase() + header.slice(1).replace(/\s/g, ''),
      headerName: header,
      width: 150,
      editable: true,
    }));
  };

  const validateCSVData = (data) => {
  
    const errors = []; // To collect error information
  
    // Filter out completely empty rows
    const validData = data.filter(entry => {
      const isRowEmpty = requiredFields.every(field => !entry[field] || entry[field].trim() === '');
      return !isRowEmpty;
    });
  
    // Validate each entry for missing fields and collect errors
    validData.forEach((entry, index) => {
      const missingFields = requiredFields.filter(field => !(field in entry) || entry[field].trim() === '');
  
      // If there are missing fields, log the row and the missing fields
      if (missingFields.length > 0) {
        errors.push({
          row: index + 1, // CSV rows are usually 1-based, not 0-based
          missingFields: missingFields
        });
      }
    });
  
    // If there are errors, return false and log the error details
    if (errors.length > 0) {
      return { isValid: false, errors: errors };
    }
  
    // Return true if all rows are valid
    return { isValid: true };
  };


  const addUsersToView = (parsedData) => {
    console.error(parsedData)
    // Convert parsedData to DynamoDB-like document format
    const dynamoUsers = parsedData.map(user => ({
      BiauEmail: { S: user.BiauEmail },
      Title: { S: user.VerticalTitle },
      Type: { S: user.VerticalType },
      Description: { S: user.VerticalDescription },
      Color: { S: user.VerticalColor },
      Date: { S: user.VerticalDate },
      Location: { S: user.VerticalLocation },
      LogoUrl: { S: user.LogoUrl },
      BackgroundUrl: { S: user.BackgroundUrl }
    }));

    
    const currentTab = tabsConfig[activeTab];
    // Find the correct vertical by matching the current tab name
    const matchedVertical = verticalUsers.find(item => item.vertical === currentTab.name);

    // Add the mapped user to the correct vertical tab
    if (matchedVertical) {
      for (let index = 0; index < dynamoUsers.length; index++) {
        const element = dynamoUsers[index];
        matchedVertical.users.Items.push(element);
      }
    } else {
      console.error('Vertical not found for the current tab');
    }
    // Assume verticalUsers is structured to hold users for the current tab (subscriptions, tickets, etc.)
    // Find the current vertical's users and append the new ones
    setVerticalUsers(prevVerticalUsers => {
      // If the vertical for the current tab already exists, append to it
      if (prevVerticalUsers[currentTab.index]) {
        return prevVerticalUsers.map((vertical, index) => {
          if (index === currentTab.index) {
            return {
              ...vertical,
              users: {
                ...vertical.users,
                Items: [...vertical.users.Items, ...dynamoUsers]  // Append new users
              }
            };
          }
          return vertical;
        });
      } else {
        // If the vertical doesn't exist, create a new entry
        return [
          ...prevVerticalUsers,
          {
            vertical: activeTab,
            users: { Items: dynamoUsers }
          }
        ];
      }
    });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };


  // Updated renderFileUpload function
  const renderFileUpload = (fileHandler, uploadHandler) => (
    <div className="file-card">
      <div className='aligned-form'>
        {/* Guidance Text */}
        <p style={{ color: 'white' }}>Please upload a CSV file to add verticals.</p>
  
        {/* Toggle Switch to Change Between Form/File Upload */}
        <div className="toggleDiv" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <p style={{ color: 'white', margin: 0 }}>Add using form</p>
  
          <div
            className={`toggle-switch ${toggleFormType ? 'active' : ''}`}
            onClick={() => setToggleFormType(!toggleFormType)}
          >
            <div className={`toggle-slider ${toggleFormType ? 'active' : ''}`}></div>
          </div>
        </div>
        <br />
  
        {/* File Upload Input */}
        <label className='label'>Add {activeTab}</label>
        <br />
        <input
          key={fileInputKey} // Use the key prop to force input reset
          type="file"
          accept=".csv"
          onChange={fileHandler}
        />
        <br />
        {loading ? (
          <CircularProgress style={{ color: 'white', marginTop: '20px' }} />
        ) : (
          <button className="form-button" onClick={uploadHandler}>Upload</button>
        )}
      </div>
    </div>
  );
  
  // Updated renderForm function
  const renderForm = (formFields, submitHandler, buttonText) => (
    <div className="form-card">
      {/* Guidance Text */}
      <p style={{ color: 'white' }}>Please fill out the form to add a vertical.</p>
  
      {/* Toggle Switch to Change Between Form/File Upload */}
      <div className="toggleDiv" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <p style={{ color: 'white', margin: 0 }}>Add using CSV</p>
  
        <div
          className={`toggle-switch ${toggleFormType ? 'active' : ''}`}
          onClick={() => setToggleFormType(!toggleFormType)}
        >
          <div className={`toggle-slider ${toggleFormType ? 'active' : ''}`}></div>
        </div>
      </div>
      <br />
  
      {/* Form Inputs */}
      <label className='label'>Add {activeTab}</label>
      <form className="aligned-form" onSubmit={submitHandler}>
        {formFields.map((field, index) => (
          <div className="aligned-form" key={index}>
            {field.type === 'select' ? (
              <select
                className="form-input"
                value={field.value}
                onChange={field.onChange}
                required={field.required}
              >
                <option value="" disabled>{field.placeholder}</option>
                {field.options.map((option, idx) => (
                  <option key={idx} value={option}>{option}</option>
                ))}
              </select>
            ) : (
              <input
                type={field.type}
                className="form-input"
                placeholder={field.placeholder}
                value={field.value}
                onChange={field.onChange}
                required={field.required}
              />
            )}
          </div>
        ))}
        <div className="form-group">
          {loading ? (
            <CircularProgress style={{ color: 'white', marginTop: '20px' }} />
          ) : (
            <button type="submit" onClick={submitHandler} className="form-button">{buttonText}</button>
          )}
        </div>
      </form>
    </div>
  );
  
  // Loading State and Improved Upload and Submit Handlers
  const handleBulkUpload = async () => {
    setLoading(true); // Set loading state to true
    try {
      const response = await fetch('https://dmzaxtvo7ubm2vgant5e4f4i740frwjy.lambda-url.eu-west-2.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify(parsedData)
      });
      if (response.ok) {
        const result = await response.json();
        addUsersToView(parsedData);
        setFileInputKey(Date.now());
        setToggleForm(false); // Close the dialog on success
      } else {
        addUsersToView(parsedData);
        throw new Error('Bulk upload failed');
      }
    } catch (error) {
      addUsersToView(parsedData);
      console.error('Failed to bulk upload:', error);
    } finally {
      setLoading(false); // Set loading state to false
    }
  };
  
  const saveVertical = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading state to true
  
    if (!activeTab || !shorthand || !email || !orgName || !title || !biauType || !description || !color || !verticalDate || !verticalLocation) {
      alert("Please fill in all required fields before submitting.");
      setLoading(false); // Set loading state to false
      return; // Stop the function if any required field is missing
    }
  
    const payload = {
      VerticalName: capitalizeFirstLetter(activeTab),
      ShortHand: shorthand.toUpperCase(),
      BiauEmail: email.toLocaleLowerCase(),
      OrganisationName: orgName,
      VerticalTitle: title,
      VerticalType: biauType,
      VerticalDescription: description,
      VerticalColor: color,
      VerticalDate: verticalDate,
      VerticalLocation: verticalLocation,
      LogoUrl: logoUrl,
      CommunicationEmail: communicationEmail,
    };
  
    const url = 'https://ihdcf3u4ucgd7aighpzsr3chdq0jcbsa.lambda-url.eu-west-2.on.aws/';
    const requestData = {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
      },
      body: JSON.stringify(payload),
    };
  
    try {
      const response = await fetch(url, requestData);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      const parsedData = JSON.parse(responseData.Message); // Parse the stringified message
      const user = parsedData.user;
  
      // Transform the new user data into the desired format for dataRows
      const newRow = {
        id: dataRows.length + 1,
        email: user.BiauEmail?.S || 'No Email',
        title: user.VerticalTitle?.S || 'No Title',
        type: user.VerticalType?.S || 'No Type',
        description: user.VerticalDescription?.S || 'No Description',
        color: user.VerticalColor?.S || 'No Color',
        date: user.VerticalDate?.S || 'No Date',
        location: user.VerticalLocation?.S || 'No Location',
        logoUrl: user.LogoUrl?.S || 'No LogoUrl',
        backgroundUrl: user.BackgroundUrl?.S || 'No BackgroundUrl',
        verticalId: user[`${capitalizeFirstLetter(activeTab)}Id`]?.S || 'No VerticalId',
      };
  
      // Update the dataRows state with the new row
      setDataRows((prevRows) => [...prevRows, newRow]);
  
      setToggleForm(false); // Close the dialog on success
    } catch (error) {
      console.error('Error during vertical creation:', error);
      alert('Failed to create vertical. Please try again.');
    } finally {
      setLoading(false); // Set loading state to false
    }
  };
  
  
  const handleApplyFilter = () => {
    setLastEvaluatedKey(null);
    // Reset pagination model to page 0
    setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    // Fetch data from the first page after applying the filter
    fetchFilteredData(1, paginationModel.pageSize, true, null);
  };

  useEffect(() => {
    if (filterInput.trim() === '') {
      setIsFilterApplied(false); // Filter is cleared
    } else {
      setIsFilterApplied(true); // Filter is applied
    }
  }, [filterInput]);

  
  const tabsConfig = {
    subscriptions: {
      name: "Subscriptions",
      index: 2,
      headers: ["Email", "Title", "Type", "Description", "Color", "Date", "Location", "LogoUrl", "BackgroundUrl"],
      formFields: [
        { 
          type: 'email', 
          placeholder: 'Email address', 
          value: email, 
          onChange: (e) => setNewEmail(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Title', 
          value: title, 
          onChange: (e) => setTitle(e.target.value), 
          required: true 
        },
        { 
          type: 'select', 
          placeholder: 'biau Type', 
          value: biauType, 
          onChange: (e) => setBiauType(e.target.value), 
          required: true, 
          options: ['Waterpolo', 'Synchroonzwemmen', 'Fit&Fun', 'Vrijwilligers', 'Sponsor', 'Steunend lid'] 
        },
        { 
          type: 'text', 
          placeholder: 'Description', 
          value: description, 
          onChange: (e) => setDescription(e.target.value), 
          required: true 
        },
        { 
          type: 'color', 
          placeholder: 'Color', 
          value: color, 
          onChange: (e) => setColor(e.target.value), 
          required: true 
        },
        { 
          type: 'datetime-local', 
          placeholder: 'Event Date & Time', 
          value: verticalDate, 
          onChange: (e) => setVerticalDate(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Event Location', 
          value: verticalLocation, 
          onChange: (e) => setVerticalLocation(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Logo Url', 
          value: logoUrl, 
          onChange: (e) => setLogoUrl(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Background Url', 
          value: backgroundUrl, 
          onChange: (e) => setBackgroundUrl(e.target.value), 
          required: true 
        },
      ],
    },
    
    tickets: {
      index: 3,
      name: "Tickets",
      headers: ["Email", "Title", "Type", "Description", "Color", "Date", "Location", "LogoUrl", "BackgroundUrl"],
      formFields: [
        { 
          type: 'email', 
          placeholder: 'Email address', 
          value: email, 
          onChange: (e) => setNewEmail(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Ticket Title', 
          value: title, 
          onChange: (e) => setTitle(e.target.value), 
          required: true 
        },
        { 
          type: 'select', 
          placeholder: 'Area', 
          value: biauType, 
          onChange: (e) => setBiauType(e.target.value), 
          required: true, 
          options: ['A', 'B', 'C', 'Standing', 'Special'] 
        },
        { 
          type: 'text', 
          placeholder: 'Game Description', 
          value: description, 
          onChange: (e) => setDescription(e.target.value), 
          required: true 
        },
        { 
          type: 'color', 
          placeholder: 'Color', 
          value: color, 
          onChange: (e) => setColor(e.target.value), 
          required: true 
        },
        { 
          type: 'datetime-local', 
          placeholder: 'Event Date & Time', 
          value: verticalDate, 
          onChange: (e) => setVerticalDate(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Event Location', 
          value: verticalLocation, 
          onChange: (e) => setVerticalLocation(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Logo Url', 
          value: logoUrl, 
          onChange: (e) => setLogoUrl(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Background Url', 
          value: backgroundUrl, 
          onChange: (e) => setBackgroundUrl(e.target.value), 
          required: true 
        },
      ],
    },
  
    accreditations: {
      index: 0,
      name: "Accreditations",
      headers: ["Email", "Title", "Type", "Description", "Color", "Date", "Location", "LogoUrl", "BackgroundUrl"],
      formFields: [
        { 
          type: 'email', 
          placeholder: 'Email address', 
          value: email, 
          onChange: (e) => setNewEmail(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Accredited Title', 
          value: title, 
          onChange: (e) => setTitle(e.target.value), 
          required: true 
        },
        { 
          type: 'select', 
          placeholder: 'Type', 
          value: biauType, 
          onChange: (e) => setBiauType(e.target.value), 
          required: true, 
          options: ['referee', 'player', 'coach', 'medic'] 
        },
        { 
          type: 'text', 
          placeholder: 'Description', 
          value: description, 
          onChange: (e) => setDescription(e.target.value), 
          required: true 
        },
        { 
          type: 'color', 
          placeholder: 'Color', 
          value: color, 
          onChange: (e) => setColor(e.target.value), 
          required: true 
        },
        { 
          type: 'datetime-local', 
          placeholder: 'Event Date & Time', 
          value: verticalDate, 
          onChange: (e) => setVerticalDate(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Event Location', 
          value: verticalLocation, 
          onChange: (e) => setVerticalLocation(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Logo Url', 
          value: logoUrl, 
          onChange: (e) => setLogoUrl(e.target.value), 
          required: true 
        },
        { 
          type: 'text', 
          placeholder: 'Background Url', 
          value: backgroundUrl, 
          onChange: (e) => setBackgroundUrl(e.target.value), 
          required: true 
        },
      ],
    },
  };
  
  const renderTabContent = () => {
    if (!activeTab || !tabsConfig[activeTab]) {
      return <p>Please select a valid tab</p>;
    }
  
    const currentTab = tabsConfig[activeTab];
    // Columns for DataGrid
    const columns = getColumns(currentTab.headers);
  
    return (
      <div>
        {/* Filter Controls */}
        <div className="filter-controls">
          <select
            className="filter-select"
            value={filterColumn}
            onChange={(e) => setFilterColumn(e.target.value)}
          >
            {columns.map((col) => (
              <option key={col.field} value={col.field}>
                {col.headerName}
              </option>
            ))}
          </select>
          <input
            type="text"
            className="filter-input"
            placeholder="Enter filter value"
            value={filterInput}
            onChange={(e) => setFilterInput(e.target.value)}
          />
          <button className="filter-button" onClick={handleApplyFilter}>
            Apply Filter
          </button>
        </div>
  
        {/* DataGrid Display */}
        <Box sx={{ height: 400, width: '100%' }}>
          <div
            style={{
              borderRadius: 5,
              display: 'flex',
              backgroundColor: '#142145',
              justifyContent: 'flex-end',
              padding: '8px',
            }}
          >
            {selectedRows.length === 1 && (
              <IconButton
                style={{ marginRight: '8px', color: 'white' }}
                onClick={handleEditClick}
              >
                <EditIcon />
              </IconButton>
            )}
            {selectedRows.length > 0 && (
              <IconButton
                style={{ marginRight: '8px', color: 'white' }}
                onClick={handleDeleteClick}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
          <DataGrid
            rows={dataRows}
            columns={columns}
            pageSizeOptions={isFilterApplied ? [pageSize] : [5, 10, 25]} // Keep pageSize static when filter is applied
            rowCount={rowCount}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            disablePagination={isFilterApplied} // Disable pagination when a filter is applied
            onPaginationModelChange={(model) => {
              if (!isFilterApplied) {
                // Allow pagination changes only if no filter is applied
                setPaginationModel(model);
                fetchFilteredData(model.page + 1, model.pageSize);
              }
            }}
            loading={dataRows.length === 0}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
            }}
            disableColumnMenu={isFilterApplied} // Optional: Disable other column actions when filter is applied
            sx={{
              '& .MuiDataGrid-footerContainer': {
                backgroundColor: isFilterApplied ? '#000' : '#fff', // Set pagination footer background color depending on filter
                color: isFilterApplied ? '#fff' : '#000', // Set text color depending on filter
              },
              '& .MuiTablePagination-root': {
                color: isFilterApplied ? '#fff' : '#000', // Set pagination controls text color depending on filter
              },
              '& .MuiTablePagination-actions > button:first-child': {
                display: 'none', // This hides the back button
              },
            }}
          />
        </Box>
  
        {/* Form Toggle Button */}
        <div className="form-toggle">
          <button
            onClick={() => setToggleForm(!toggleForm)}
            className="rounded-button"
          >
            {toggleForm ? '-' : '+'}
          </button>
        </div>
  
        <Dialog
  open={toggleForm}
  onClose={() => setToggleForm(false)}
  PaperProps={{
    style: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      overflow: 'hidden', // Remove scrollbar
      width: '70vw', // Increase width to make it wider
      maxWidth: 'none', // Prevent width restriction by default dialog settings
    },
  }}
>
      <DialogContent style={{ overflow: 'hidden', padding: 0 }}>
        {toggleFormType
          ? renderForm(currentTab.formFields, saveVertical, 'Submit')
          : renderFileUpload(handleFileChange, handleBulkUpload)}
      </DialogContent>
    </Dialog>
          </div>
    );
  };
  
  
useEffect(() => {
  // This will reset the file input key when the activeTab changes
  setFileInputKey(Date.now());
}, [activeTab]);

  useEffect(() => {
    let orgName = localStorage.getItem('orgName');

    // Check if the logged-in user has access
    const LoggedInUserHasAcces = async () => {
      // Replace with real access control logic
      return true;
    };

    if (LoggedInUserHasAcces()) {
      const GetOrganisationInfo = async () => {
        setLoading(true);  // Start loading
        const url = 'https://755e4dp26rgnirtlxivngn7efq0wfwyq.lambda-url.eu-west-2.on.aws/';
        try {
          const requestData = {
            method: 'POST',
            headers: {
              'Content-Type': 'text/plain',
            },
            body: JSON.stringify({ orgName: orgName })
          };
          const response = await fetch(url, requestData);

          const data = await response.json();
          // Deserialization logic for organisation info
          const {OrganisationName ,Verticals, PaymentInfo, AdminEmail,CommunicationEmail,ShortHand } = data;
          // Update state based on the fetched data
          setOrgName(OrganisationName?.S || '');
          setShortHand(ShortHand?.S || '');
          setVerticals(Verticals?.L.map(vert => vert.S) || []);
          setAdminEmail(AdminEmail?.S || '');
          setCommunicationEmail(CommunicationEmail?.S || '');
          setPaymentInfo({
            amount: PaymentInfo?.M?.Amount?.N || '',
            periodicity: PaymentInfo?.M?.Periodicity?.S || 'Yearly',
          });
        } 
        catch (error) {
          
        } 
        try {
          if(shorthand == null){

          const requestData2 = {
            method: 'POST',
            headers: {
              'Content-Type': 'text/plain',
            },
            body: JSON.stringify({ shortHand: orgName.toUpperCase() })
          };
          const response2 = await fetch(url, requestData2);

          const data2 = await response2.json();
          // Deserialization logic for organisation info
          const {OrganisationName ,Verticals, PaymentInfo, AdminEmail,ShortHand } = data2;
          // Update state based on the fetched data
          setOrgName(OrganisationName?.S || '');
          setShortHand(ShortHand?.S || '');
          setVerticals(Verticals?.L.map(vert => vert.S) || []);
          setAdminEmail(AdminEmail?.S || '');
          setPaymentInfo({
            amount: PaymentInfo?.M?.Amount?.N || '',
            periodicity: PaymentInfo?.M?.Periodicity?.S || 'Yearly',
          });
          }
        } catch (error) {
          console.error('Fetching error:', error);
          setErrorMessage('Failed to fetch organization info');
        }
        finally {
          setLoading(false);  // End loading
        }
      };

      // Initialize the data by fetching org info and vertical users
      const initializeData = async () => {
        await GetOrganisationInfo();
      };

      initializeData();
    }
  }, []);



  return (
    <div className="ErpSystem">
      <header className="ErpSystem-header">
        <h1>{orgName} Company Management</h1>
        <div className="tab">
          <button
            className={`tablinks ${activeTab === 'subscriptions' ? 'active' : ''}`}
            onClick={() => setActiveTab('subscriptions')}
          >
            Subscriptions
          </button>
          <button
            className={`tablinks ${activeTab === 'accreditations' ? 'active' : ''}`}
            onClick={() => setActiveTab('accreditations')}
          >
            Accreditations
          </button>
          <button
            className={`tablinks ${activeTab === 'tickets' ? 'active' : ''}`}
            onClick={() => setActiveTab('tickets')}
          >
            Tickets
          </button>
        </div>
      </header>
      <div className="tabcontent">
        {renderTabContent()}
  
        {/* Delete Confirmation Dialog */}
        <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the selected rows?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
            <Button onClick={handleConfirmDelete} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      
    {/* Edit User Information Dialog */}
    <Dialog
      open={isEditDialogOpen}
      onClose={handleCloseEditDialog}
      PaperProps={{
        style: {
          backgroundColor: '#142145', // Set to match the overall theme
          color: 'white',
          padding: '20px',
          width: '50%', // Wider dialog for better layout
          maxWidth: 'none', // Ensure it's not constrained by default max width
          borderRadius: '8px',
        },
      }}
    >
  <DialogTitle style={{ color: 'white', textAlign: 'center' }}>Edit User Information</DialogTitle>
  <DialogContent>
    <Box component="form" noValidate sx={{ mt: 1 }}>
      {/* Email Input */}
      <div className="aligned-form">
        <input
          type="text"
          value={editUserData.email}
          onChange={(e) =>
            setEditUserData({ ...editUserData, email: e.target.value })
          }
          placeholder="Email"
          className="form-input"
          required
        />

        {/* Title Input */}
        <input
          type="text"
          value={editUserData.title}
          onChange={(e) =>
            setEditUserData({ ...editUserData, title: e.target.value })
          }
          placeholder="Title"
          className="form-input"
          required
        />

        {/* Description Input */}
        <input
          type="text"
          value={editUserData.description}
          onChange={(e) =>
            setEditUserData({ ...editUserData, description: e.target.value })
          }
          placeholder="Description"
          className="form-input"
          required
        />

        {/* Logo URL Input */}
        <input
          type="text"
          value={editUserData.logoUrl}
          onChange={(e) =>
            setEditUserData({ ...editUserData, logoUrl: e.target.value })
          }
          placeholder="Logo URL"
          className="form-input"
          required
        />

        {/* Background URL Input */}
        <input
          type="text"
          value={editUserData.backgroundUrl}
          onChange={(e) =>
            setEditUserData({ ...editUserData, backgroundUrl: e.target.value })
          }
          placeholder="Background URL"
          className="form-input"
          required
        />

        {/* Date Input */}
        <input
          type="text"
          value={editUserData.verticalDate}
          onChange={(e) =>
            setEditUserData({ ...editUserData, verticalDate: e.target.value })
          }
          placeholder="Date"
          className="form-input"
          required
        />

        {/* Vertical Type Input */}
        <select
          value={editUserData.verticalType}
          onChange={(e) =>
            setEditUserData({ ...editUserData, verticalType: e.target.value })
          }
          className="form-input"
          required
        >
          <option value="" disabled>
            Select Vertical Type
          </option>
          <option value="Waterpolo">Waterpolo</option>
          <option value="Synchroonzwemmen">Synchroonzwemmen</option>
          <option value="Fit&Fun">Fit&Fun</option>
          <option value="Vrijwilligers">Vrijwilligers</option>
          <option value="Sponsor">Sponsor</option>
          <option value="Steunend lid">Steunend lid</option>
        </select>

        {/* Color Input */}
        <input
          type="color"
          value={editUserData.color}
          onChange={(e) =>
            setEditUserData({ ...editUserData, color: e.target.value })
          }
          placeholder="Color"
          className="form-input"
          required
        />

        {/* Save Button - Horizontally Aligned */}
        <div className="form-group" style={{ textAlign: 'center' }}>
          <button onClick={handleConfirmEdit} className="form-button">
            Save
          </button>
        </div>
      </div>
    </Box>
  </DialogContent>
</Dialog>


      </div>
    </div>
  );
}

export default Organisations;